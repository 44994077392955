import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Banner from '../../components/Auth/Banner'
import Footer from '../../components/Auth/Footer'
import LoginForm from '../../components/Auth/LoginForm'
import { LoaderRocket } from '../../components/common/LoaderRocket'

import { RouteNames } from '../../../router'
import { Container, LeftContainer } from './styles'
import { useAuth } from 'widgets-base'

const Login = () => {
    if (window.location.host === 'www.deploy.link') {
        window.location.href = 'https://app.deploy.link/'
    }

    // Route to app.deploy.link


    const { currentUser, loading } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser) {
            //todo: !! if not verified !!
            // navigate(RouteNames.VERIFY_EMAIL)

            if (!currentUser.username) {
                navigate(RouteNames.USERNAME)
            } else {
                navigate(RouteNames.DASHBOARD)
            }
        }
    }, [currentUser])

    if (loading) {
        return <LoaderRocket />
    }

    return (
        <Container>
            <LeftContainer>
                <img
                    src="/images/deployable-logo.png"
                    alt="deployable"
                    height={23}
                    width={150}
                    className="logo"
                />
                <LoginForm />
                <Footer />
            </LeftContainer>
            <Banner />
        </Container>
    )
}

export default Login
